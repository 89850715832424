<template>
  <el-row class="tac" style="margin-top:4px">
    <el-col :span="12">
      <div>
        <img :src="userInfo.photo ? $beforeUrl(userInfo.photo) : require('@/assets/template/first/personal/top.png')" class="topImg">
        <div class="nickname">{{ userInfo.nickname || '无昵称' }}</div>
        <div class="changeInfo center click" @click="$go('/personal/UpdateAccount')">修改用户资料</div>
      </div>
      <el-menu
        :default-active="selected"
        class="el-menu-vertical-demo"
        @select="selectMethod"
      >
        <el-menu-item v-for="(item,index) in leftData" :key="index" :index="index+''">
          <div v-if="selected == index" class="menuItem">
            <div class="leftItem">
              <img class="leftImg" :src="item.imgSelected">
              <span class="titleSelected">{{ item.name }}</span>
            </div>
            <img class="jt" :src="require('@/assets/template/first/personal/jt-selected.png')">
          </div>
          <div v-else class="menuItem">
            <div class="leftItem">
              <img class="leftImg" :src="item.img">
              <span class="title">{{ item.name }}</span>
            </div>
            <img class="jt" :src="require('@/assets/template/first/personal/jt.png')">
          </div>
        </el-menu-item>
      </el-menu>
    </el-col>
  </el-row>
</template>

<script>
export default {
  data() {
    return {
      leftData: [
        { name: '群众随手拍', img: require(`@/assets/template/${this.$store.getters.moduleName}/personal/1.png`), imgSelected: require(`@/assets/template/${this.$store.getters.moduleName}/personal/11.png`), url: '/personal/Readily' },
        { name: '留言建议', img: require(`@/assets/template/${this.$store.getters.moduleName}/personal/2.png`), imgSelected: require(`@/assets/template/${this.$store.getters.moduleName}/personal/22.png`), url: '/personal/Proposal' },
        { name: '需求对接', img: require(`@/assets/template/${this.$store.getters.moduleName}/personal/3.png`), imgSelected: require(`@/assets/template/${this.$store.getters.moduleName}/personal/33.png`), url: '/personal/Demand' },
        { name: '困难帮扶', img: require(`@/assets/template/${this.$store.getters.moduleName}/personal/4.png`), imgSelected: require(`@/assets/template/${this.$store.getters.moduleName}/personal/44.png`), url: '/personal/DifficultyPeople' },
        { name: '在线咨询', img: require(`@/assets/template/${this.$store.getters.moduleName}/personal/5.png`), imgSelected: require(`@/assets/template/${this.$store.getters.moduleName}/personal/55.png`), url: '/personal/Online' },
        { name: '对话书记', img: require(`@/assets/template/${this.$store.getters.moduleName}/personal/6.png`), imgSelected: require(`@/assets/template/${this.$store.getters.moduleName}/personal/66.png`), url: '/personal/Dialogue' },
        { name: '监督举报', img: require(`@/assets/template/${this.$store.getters.moduleName}/personal/7.png`), imgSelected: require(`@/assets/template/${this.$store.getters.moduleName}/personal/77.png`), url: '/personal/Supervise' },
        { name: '信息监管', img: require(`@/assets/template/${this.$store.getters.moduleName}/personal/8.png`), imgSelected: require(`@/assets/template/${this.$store.getters.moduleName}/personal/88.png`), url: '/personal/Information' },
        { name: '在线投诉', img: require(`@/assets/template/${this.$store.getters.moduleName}/personal/9.png`), imgSelected: require(`@/assets/template/${this.$store.getters.moduleName}/personal/99.png`), url: '/personal/Complain' }
      ],
      selected: -1
    }
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo
    }
  },
  watch: {
    $route() {
      this.init()
    }
  },
  created() {
    this.init()
  },
  methods: {
    selectMethod(val) {
      if (val == this.selected) return
      this.$router.push({ path: this.leftData[val].url, query: { selected: val }})
    },
    init() {
      this.param = this.$getParams()
      this.selected = this.param.selected || -1
    }
  }
}
</script>

<style lang="scss" scoped>
.tac{
  padding-bottom:50px;
}
  ::v-deep{
    .el-col-12{
      border: 1px solid #EEEEEE;
      width: 100%;
      .el-menu{
        border: unset;
      }
    }
  }
  .topImg{
    width: 90px;
    height: 90px;
    border-radius: 50%;
    margin: 21px auto 18px auto;
    display: block;
  }
  .nickname{
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    text-align: center;
  }
  .changeInfo{
    width: 136px;
    height: 36px;
    border: 1px solid var(--theme);
    border-radius: 4px;
    color: var(--theme);
    margin: 20px auto 20px auto;
  }
  .menuItem{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .leftImg{
      width: 18px;
      height: 18px;
      margin-right: 10px;
    }
    .titleSelected{
      color: var(--theme);
    }
  }
</style>
