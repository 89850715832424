<template>
  <div>
    <component :is="component[type]" />
  </div>
</template>

<script>
export default {
  props: {
  },
  data() {
    return {
      component: {
        nav: () => import('./components/nav.vue'),
        setting: () => import('./components/setting.vue')
      },
      type: 'setting'
    }
  },
  watch: {
    $route() {
      this.initType()
    }
  },

  mounted() {

  },
  created() {
    this.initType()
  },

  methods: {
    initType() {
      this.type = this.$route.meta.type || ''
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
