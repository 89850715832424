<template>
  <div class="personal">
    <left ref="left" style="width:220px;margin-right:40px" />
    <div class="right">
      <rightTop />
      <router-view class="rightContentss" />
    </div>

  </div>
</template>

<script>
import left from './components/left'
import rightTop from './components/rightTop/index.vue'
export default {
  components: { left, rightTop },
  data() {
    return {
    }
  },
  mounted() {
  },
  updated() {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
  .personal{
    width: 1300px;
    margin: 20px auto 0 auto;
    display: flex;
    .right{
      flex:1;
      overflow: hidden;
    }
  }
</style>
